import '../../../admin/js/app/components/datepicker_localization';

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

window.processAjaxSubmit = ($form, onSuccess, onError) => response => {
    $form.trigger('form-ajax', [response]);
    if (response.result == 'success') {
        hideErrors($form);
        $form.trigger('form-ajax-success', [response]);
        if (response.message) toastr.success(response.message);
        if (response.redirect) setTimeout(() => window.location.href = response.redirect, 0);
        if ($form.hasClass('js_form-step-order')) {
            setTimeout(() => window.location.href = '/order', 0)
        };
    } else {
        $form.trigger('form-ajax-error', [response]);
        toastr.error(response.message || 'Ошибка при отправке формы!');
    }
    $form.find('input[type="submit"]').attr('disabled', false);
    $form.find('.js_btn').attr('disabled', false);
}

function hideErrors($form) {
    const formId = $form.attr('id');

    $('.error-block.has-error').removeClass('has-error').empty();
    $('[form="' + formId + '"]').removeClass('has-error');
}

$(document).on('submit', '.js_ajax-form', submitAjaxForm);

function submitAjaxForm() {
    let $form = $(this);
    $form.find('input[type="submit"]').attr('disabled', true);
    $form.find('.js_btn').attr('disabled', true);
    $form.ajaxSubmit({
        data: {'is_ajax': 1},
        success: window.processAjaxSubmit($form),
        error: onError,
    });

    return false;
}

function onError(jqXHR, textStatus, error, $form) {
    hideErrors($form);

    const data = JSON.parse(jqXHR.responseText);
    $form.trigger('panel-form-ajax-error', [data]);
    const formId = $form.prop('id');

    if (data) {
        if (data.errors) {
            $.each(data.errors, function (input, errors) {
                let inputArray = input.split('.');
                let $input = $form.find(':input[name="' + input + '"]');

                if (!$input.length) {
                    $input = $(':input[name="' + input + '"][form="' + formId + '"]')
                }

                if (!$input.length && inputArray.length === 1) {
                    $input = $form.find(':input[name="' + inputArray[0] + '[]"]:eq(' + inputArray[1] + ')');
                }
                if (inputArray.length === 2) {
                    $input = $form.find(`:input[name="${inputArray[0]}[${inputArray[1]}]"]`);
                }
                if (inputArray.length === 3) {
                    $input = $form.find(`:input[name="${inputArray[0]}[${inputArray[1]}][${inputArray[2]}]"]`);
                }
                let text = '';
                $.each(errors, (i, error) => text += error + "<br>");

                if ($input.length) {
                    // let $wrapper = $input.closest('.form-group');
                    // let $wrapper = $input.next('.form-group');
                    // let $error_block = $wrapper.find('.error-block');
                    let $error_block = $input.next('.error-block');
                    $error_block.addClass('has-error');
                    $input.addClass('has-error');
                    let $help_block = '<span class="help-block">' + text + '</span>';
                    $error_block.append($help_block);
                } else {
                    toastr.error(text, 'error');
                }
            });
        }

        if (data.view) {
            $('.js_table-wrapper').html(data.view);
        }
    }

    const errorMessages = getErrorMessages(jqXHR);
    console.error(errorMessages);
    toastr.error('Ошибка отправки формы: ' + errorMessages.join('<br>'), 'error');
}
function getErrorMessages(jqXHR) {
    let errorMessages = [];
    const responseData = JSON.parse(jqXHR.responseText);

    const possibleErrorMessageProps = ['error', 'exception', 'message'];

    $.each(possibleErrorMessageProps, function (index, propName) {
        if (responseData[propName]) {
            errorMessages.push(responseData[propName]);
        }
    });

    if (responseData.errors) {
        errorMessages = errorMessages.concat(Object.entries(responseData.errors).map(([key, messages]) => messages.join('<br>')));
    }

    return errorMessages;
}



function init() {
    maskPhone();
}

init();

function maskPhone() {
    // $('.js_mask-phone').inputmask('+375 (99) 999-99-99');
}

function datePicker() {
    $('.js_date-pick').datepicker();
    $('.js_datepicker').datepicker();
}

window.datePicker = datePicker;

datePicker();